import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Delete } from "@mui/icons-material";
import { array, boolean, object, string } from "yup";
import validationMessage from "../../../../constants/validationMessage";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SmartImageUpload from "../../../../components/ImageUpload/SmartImageUpload";
import TextAreaComponent from "../../../../components/TextArea/TextAreaComponent";
import { makeid } from "../../../../utils";
import productService from "../../../../services/productPostService";
import Swal from "sweetalert2";
import { CountryType, DestinationType } from "../../../../interfaces/product";
import {
  DataDetailGoflCourse,
  GolfCourseHandleForm,
  GolfCourseItem,
  ItineraryGolfCourse,
} from "../../../../interfaces/golfCourse";
import dataCountryAndCity from "../../../../utils/data/dataCountryAndCity";
import GroupCoverImage from "./GroupCoverImage";

const validationSchema = object().shape({
  articleCode: string()
    .max(15, "Must be exactly 15 characters")
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  status: string().required(validationMessage.required),
  language: string().required(validationMessage.required),
  // type: string().required(validationMessage.required),
  publishExpiredDate: string().required(validationMessage.required),
  title: string().required(validationMessage.required),
  country: string().required(validationMessage.required),
  location: array().required(validationMessage.required),
  championship: string().required(validationMessage.required),
  designer: string().required(validationMessage.required),
  holes: string().required(validationMessage.required),
  par: string().required(validationMessage.required),
  metres: string().required(validationMessage.required),
  // facilities: string().required(validationMessage.required),

  golfCourseContents: array()
    .of(
      object().shape({
        content: string().required(validationMessage.required),
        isIncludesImage: boolean().required(validationMessage.required),
        includesImage: string()
          .nullable()
          .transform((value) => (value === undefined ? null : value)),
      })
    )
    .required(validationMessage.required),
  // coverImage: string().required(validationMessage.required),
  coverImage: array()
    .of(
      object().shape({
        images: string()
          .nullable()
          .transform((value) => (value === undefined ? null : value)),
      })
    )
    .nullable()
    .transform((value: { images?: string }[]) => {
      let hasValue = false;
      if (value !== undefined) {
        value.forEach((item) => {
          if (item.images && item.images.trim()) hasValue = true;
        });
      }
      return value === undefined || !hasValue ? [] : value;
    }),
});
interface Props {
  dataDetail?: GolfCourseItem;
  open: boolean;
  setOpen: (data: boolean) => void;
  callback: () => void;
  listAllCountry: CountryType[];
  isView?: boolean;
  defaultLanguage?: string;
  isNewLanguage?: boolean;
  setIsView?: (data: boolean) => void;
}

const GolfCourseForm = ({
  open,
  setOpen,
  dataDetail,
  callback,
  listAllCountry,
  isView,
  defaultLanguage,
  isNewLanguage,
  setIsView,
}: Props) => {
  const [keyReset, setKeyReset] = useState("");
  const [countriesSelect, setCountriesSelect] = useState<CountryType>();
  const [listLocations, setListLocations] = useState<string[]>([]);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [golfCourseId, setGolfCourseId] = useState<number | null>(null);
  const fetchLocation = async () => {
    setLoadingLocation(true);

    try {
      if (countriesSelect && countriesSelect.id) {
        let response = await productService.getListDestinationsByCountries({
          countriesId: [countriesSelect.id],
        });
        if (response?.status === 200) {
          let data: DestinationType[] = response?.data;
          setListLocations(data.map((item) => item.name));
        }
      }
    } catch (error) {
      console.log(error);
    }

    setLoadingLocation(false);
  };
  useEffect(() => {
    if (countriesSelect) {
      fetchLocation();
    } else {
      setListLocations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesSelect]);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm<GolfCourseHandleForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      articleCode: "",
      status: "",
      language: !isNewLanguage ? defaultLanguage ?? "en" : undefined,
      type: "",
      publishExpiredDate: "",
      title: "",
      country: "",
      location: undefined,
      championship: "",
      designer: "",
      holes: "",
      par: "",
      metres: "",
      facilities: "",
      coverImage: [
        {
          images: "",
        },
        {
          images: "",
        },
        {
          images: "",
        },
      ],
      golfCourseContents: [
        {
          content: "",
          isIncludesImage: false,
          includesImage: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "golfCourseContents",
  });

  const submit: SubmitHandler<GolfCourseHandleForm> = async (data) => {

    console.log(data.coverImage);
    
    const payload = {
      ...data,
      coverImage: JSON.stringify(data.coverImage ?? [{
        images: "",
      },
      {
        images: "",
      },
      {
        images: "",
      },]),
      location: JSON.stringify(data.location),
    };
    if (isNewLanguage) {
      const response = await productService.createGolfCourse(payload);

      if (response?.status === 200) {
        Swal.fire(
          "",
          response?.data?.message ?? "Create Successfully!",
          "success"
        );
        if (setIsView) {
          setIsView(true);
        }

        callback();
        // setOpen(false);
      } else {
        Swal.fire("", response?.data?.message, "error");
      }
    } else {
      if (golfCourseId) {
        const response = await productService.updateGolfCourse(
          payload,
          golfCourseId
        );
        if (response?.status === 200) {
          Swal.fire(
            "",
            response?.data?.message ?? "Update Successfully!",
            "success"
          );
          callback();
          // setOpen(false);
        } else {
          Swal.fire("", response?.data?.message, "error");
        }
      } else {
        const response = await productService.createGolfCourse(payload);

        if (response?.status === 200) {
          Swal.fire(
            "",
            response?.data?.message ?? "Create Successfully!",
            "success"
          );
          if (setIsView) {
            setIsView(true);
          }

          callback();
          setOpen(false);
        } else {
          Swal.fire("", response?.data?.message, "error");
        }
      }
    }
  };

  const error: SubmitErrorHandler<GolfCourseHandleForm> = (errors) => {
    console.log(errors, isValid);
  };

  const fetchDetailData = async (detailData: GolfCourseItem) => {
    setLoading(true);
    const response = await productService.getDetailGolfCourse(
      detailData.information.articleCode,
      defaultLanguage
    );

    if (response?.status === 200) {
      const dataGolfCourse: DataDetailGoflCourse = response?.data;
      setGolfCourseId(dataGolfCourse.content.id);
      const initData = {
        ...dataGolfCourse.information,
        title: dataGolfCourse.content?.name ?? "",
        language: !isNewLanguage ? defaultLanguage ?? "en" : undefined,
        location: JSON.parse(dataGolfCourse.information.location ?? "[]"),
        coverImage:
          typeof dataGolfCourse.information.coverImage === "string" &&
          !dataGolfCourse.information.coverImage.includes("[") &&
          !dataGolfCourse.information.coverImage.includes("]")
            ? [
                { images: dataGolfCourse.information.coverImage },
                {
                  images: "",
                },
                {
                  images: "",
                },
              ]
            : JSON.parse(dataGolfCourse.information.coverImage ?? "[]").length >
                0
              ? JSON.parse(dataGolfCourse.information.coverImage ?? "[]")
              : [
                  {
                    images: "",
                  },
                  {
                    images: "",
                  },
                  {
                    images: "",
                  },
                ],
        golfCourseContents:
          response?.data &&
          (response?.data?.content?.itineraries ?? [])?.length > 0
            ? response?.data.content.itineraries.map(
                (item: ItineraryGolfCourse) => ({
                  content: item.content,
                  isIncludesImage: item.isIncludesImage,
                  includesImage: item.includesImage,
                })
              )
            : [
                {
                  content: "",
                  isIncludesImage: false,
                  includesImage: "",
                },
              ],
      };
      reset(initData);
    } else {
      const initData = {
        ...detailData.information,
        language: !isNewLanguage ? defaultLanguage ?? "en" : undefined,
        location: JSON.parse(detailData.information.location ?? "[]"),
        coverImage:
          typeof detailData.information.coverImage === "string" &&
          !detailData.information.coverImage.includes("[") &&
          !detailData.information.coverImage.includes("]")
            ? [
                { images: detailData.information.coverImage },
                {
                  images: "",
                },
                {
                  images: "",
                },
              ]
            : JSON.parse(detailData.information.coverImage ?? "[]").length > 0
              ? detailData.information.coverImage
                ? JSON.parse(detailData.information.coverImage ?? "[]")
                : []
              : [
                  {
                    images: "",
                  },
                  {
                    images: "",
                  },
                  {
                    images: "",
                  },
                ],
        golfCourseContents: [
          {
            content: "",
            isIncludesImage: false,
            includesImage: "",
          },
        ],
      };
      reset(initData);
    }
    setLoading(false);
    setKeyReset(makeid(5));
  };
  useEffect(() => {
    if (dataDetail) {
      setCountriesSelect(
        listAllCountry.find(
          (item) => item.name === dataDetail.information?.country
        ) ?? undefined
      );
      fetchDetailData(dataDetail);
    }
  }, [dataDetail]);

  return (
    <Drawer
      disableEnforceFocus
      className={"drawer-common"}
      anchor="right"
      open={open}
    >
      <DialogTitle className="d-flex justify-between align-center text-white bg-tkg-blue">
        {`Golf Course`}
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close className="text-white" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <form
          className={isView ? "bg-readonly" : ""}
          id={`form-golf-course`}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(submit, error)(e);
          }}
        >
          <Paper className={`bg-transparent box-unset rounded-12 h-full p-16`}>
            {loading ? (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box p={2} height={"max-content"}>
                <Grid container spacing={4}>
                  <Grid item xs={7}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4}>
                        <Controller
                          key={keyReset}
                          control={control}
                          name="language"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Autocomplete
                                readOnly={isView}
                                disablePortal
                                disableClearable
                                id="language"
                                getOptionLabel={(option) => option.label}
                                value={
                                  !!isNewLanguage
                                    ? dataCountryAndCity.languageList
                                        .filter(
                                          (item) =>
                                            item.value !== defaultLanguage
                                        )
                                        .find((item) => item.value === value)
                                    : dataCountryAndCity.languageList.find(
                                        (item) => item.value === value
                                      )
                                }
                                onChange={(e, value) => {
                                  onChange(value.value);
                                }}
                                size="small"
                                options={
                                  isNewLanguage
                                    ? dataCountryAndCity.languageList.filter(
                                        (item) => item.value !== defaultLanguage
                                      )
                                    : dataCountryAndCity.languageList
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Language (*)"}
                                    defaultValue={""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    error={
                                      errors.language !== undefined ||
                                      (isNewLanguage && !value)
                                    }
                                    helperText={
                                      errors.language !== undefined ||
                                      (isNewLanguage && !value)
                                        ? errors?.language?.message ??
                                          validationMessage.required
                                        : ""
                                    }
                                  />
                                )}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Golf Code"
                          placeholder="Use sytem to automatically generate the article code"
                          {...register("articleCode")}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 19 },
                          }}
                          InputProps={{ readOnly: isView }}
                          sx={{
                            fieldset: {
                              legend: {
                                height: "15px",
                                padding: "0 6px",
                                span: { padding: "0 10px" },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Course Name (*)"
                          {...register("title")}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 19 },
                          }}
                          InputProps={{ readOnly: isView }}
                          sx={{
                            fieldset: {
                              legend: {
                                height: "15px",
                                padding: "0 8px",
                                span: { padding: "0 10px" },
                              },
                            },
                          }}
                          error={!!errors.title}
                          helperText={
                            !!errors.title ? errors.title?.message : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          key={keyReset}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Autocomplete
                                disabled={isView}
                                size="small"
                                {...register("country")}
                                fullWidth
                                disablePortal
                                id={"country"}
                                disableClearable={true}
                                value={
                                  value
                                    ? listAllCountry.find(
                                        (x) => x.name === value
                                      )
                                    : undefined
                                }
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                  setCountriesSelect(value);
                                  onChange(value.name);
                                }}
                                options={listAllCountry}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={errors.country !== undefined}
                                    helperText={
                                      errors.country !== undefined
                                        ? validationMessage.required
                                        : ""
                                    }
                                    placeholder="Select Country"
                                    label={`Country (*)`}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { fontSize: 19 },
                                    }}
                                  />
                                )}
                              />
                            );
                          }}
                          name={"country"}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          key={keyReset}
                          control={control}
                          name="location"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Autocomplete
                                disabled={isView}
                                size="small"
                                {...register("location")}
                                multiple
                                isOptionEqualToValue={() => false}
                                disablePortal
                                id="location"
                                value={value ? value : undefined}
                                onChange={(e, value) => {
                                  onChange(value);
                                }}
                                options={listLocations}
                                loading={loadingLocation}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`City (*)`}
                                    placeholder={value ? "" : "Select City"}
                                    defaultValue={""}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { fontSize: 19 },
                                    }}
                                    error={errors.location !== undefined}
                                    helperText={
                                      errors.location !== undefined
                                        ? validationMessage.required
                                        : ""
                                    }
                                  />
                                )}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          key={keyReset}
                          control={control}
                          name="championship"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Autocomplete
                                disabled={isView}
                                size="small"
                                disablePortal
                                id="championship"
                                options={["Yes", "No"]}
                                value={value ? value : null}
                                onChange={(e, value) => {
                                  if (value) {
                                    onChange(value);
                                  } else {
                                    onChange(undefined);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`Championship Course (*)`}
                                    defaultValue={""}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { fontSize: 19 },
                                    }}
                                    error={errors.championship !== undefined}
                                    helperText={
                                      errors.championship !== undefined
                                        ? errors.championship.message
                                        : ""
                                    }
                                  />
                                )}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Designer (*)"
                          {...register("designer")}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 19 },
                          }}
                          InputProps={{ readOnly: isView }}
                          sx={{
                            fieldset: {
                              legend: {
                                height: "15px",
                                padding: "0 4px",
                                span: { padding: "0 10px" },
                              },
                            },
                          }}
                          error={!!errors.designer}
                          helperText={
                            !!errors.designer ? errors.designer?.message : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Holes (*)"
                          {...register("holes")}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 19 },
                          }}
                          InputProps={{ readOnly: isView }}
                          sx={{
                            fieldset: {
                              legend: {
                                height: "15px",
                                padding: "0 4px",
                                span: { padding: "0 10px" },
                              },
                            },
                          }}
                          error={!!errors.holes}
                          helperText={
                            !!errors.holes ? errors.holes?.message : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Par (*)"
                          {...register("par")}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 19 },
                          }}
                          InputProps={{ readOnly: isView }}
                          sx={{
                            fieldset: {
                              legend: {
                                height: "15px",
                                padding: "0",
                                span: { padding: "0 10px" },
                              },
                            },
                          }}
                          error={!!errors.par}
                          helperText={!!errors.par ? errors.par?.message : ""}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Yard (*)"
                          {...register("metres")}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 19 },
                          }}
                          InputProps={{ readOnly: isView }}
                          sx={{
                            fieldset: {
                              legend: {
                                height: "15px",
                                padding: "0 4px",
                                span: { padding: "0 10px" },
                              },
                            },
                          }}
                          error={!!errors.metres}
                          helperText={
                            !!errors.metres ? errors.metres?.message : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container spacing={2} mb={3}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          key={keyReset}
                          control={control}
                          name="status"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Autocomplete
                                disabled={isView}
                                size="small"
                                disablePortal
                                id="status"
                                options={["DRAFT", "PUBLISH"]}
                                value={value ? value : null}
                                onChange={(e, value) => {
                                  if (value) {
                                    onChange(value);
                                  } else {
                                    onChange(undefined);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`Status (*)`}
                                    defaultValue={""}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { fontSize: 19 },
                                    }}
                                    error={errors.status !== undefined}
                                    helperText={
                                      errors.status !== undefined
                                        ? errors.status.message
                                        : ""
                                    }
                                  />
                                )}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          size="small"
                          fullWidth
                          type="date"
                          label="Publish Expired Date (*)"
                          {...register("publishExpiredDate")}
                          inputProps={{
                            max: "9999-12-31",
                            min: "1990-01-01",
                          }}
                          InputProps={{ readOnly: isView }}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 19 },
                          }}
                          sx={{
                            fieldset: {
                              legend: {
                                height: "15px",
                                padding: "0 16px",
                                span: { padding: "0 10px" },
                              },
                            },
                          }}
                          error={!!errors.publishExpiredDate}
                          helperText={
                            !!errors.publishExpiredDate
                              ? errors.publishExpiredDate?.message
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <GroupCoverImage control={control} isEdit={!isView} />
                      </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  borderBottom={2}
                  mt={5}
                  borderColor={"#0000001f"}
                />
                <Typography className="text-28 fw-700" pt={2}>
                  Content
                </Typography>
                {fields.map((item, idx) => (
                  <Grid pt={2} container key={idx} m={"auto"}>
                    <Grid item xs={7}>
                      <Typography
                        variant="h3"
                        component="h3"
                        fontSize={14}
                        fontWeight={800}
                        mb={1}
                        textTransform={"uppercase"}
                      >
                        {"Content"} (*)
                      </Typography>
                      <Controller
                        key={keyReset}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Box
                              className={
                                !!errors?.golfCourseContents?.[idx]?.content
                                  ? "editor-validate"
                                  : ""
                              }
                            >
                              <TextAreaComponent
                                value={value ?? ""}
                                onChange={onChange}
                                isEdit={!isView}
                                isCreate={!isView}
                                height={400}
                                rows={6}
                                error={
                                  !!errors?.golfCourseContents?.[idx]?.content
                                }
                              />
                            </Box>
                          );
                        }}
                        name={`golfCourseContents.${idx}.content`}
                      />
                      <Typography
                        sx={{ fontSize: 12, p: "4px 14px 0" }}
                        className={
                          !!errors?.golfCourseContents?.[idx]?.content
                            ? "text-color-danger"
                            : ""
                        }
                      >
                        {!!errors?.golfCourseContents?.[idx]?.content
                          ? validationMessage.required
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} justifyContent={"center"} pl={5} pt={2}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Controller
                          key={keyReset}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={isView}
                                    checked={value ? value : false}
                                    onChange={(e, checked) => onChange(checked)}
                                  />
                                }
                                label="BODY Image (size: 330x330 px)"
                              />
                            );
                          }}
                          name={`golfCourseContents.${idx}.isIncludesImage`}
                        />
                        {idx ? (
                          <IconButton
                            onClick={() => remove(idx)}
                            disabled={isView}
                          >
                            <Delete fontSize="medium" color="error" />
                          </IconButton>
                        ) : null}
                      </Box>
                      {watch(`golfCourseContents.${idx}.isIncludesImage`) && (
                        <>
                          <Controller
                            key={keyReset}
                            control={control}
                            name={`golfCourseContents.${idx}.includesImage`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <SmartImageUpload
                                  width={1120}
                                  height={690}
                                  isEdit={!isView}
                                  type={"single"}
                                  folder={"content/cover"}
                                  id={`golfCourseContents.${idx}.includesImage`}
                                  defaultValue={String(value)}
                                  onChange={(url: string) => onChange(url)}
                                  label={
                                    "Cover Image (*) ( 1120 × 690 px, Maximum: 1)"
                                  }
                                  register={register}
                                  // errors={errors}
                                />
                              );
                            }}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {idx + 1 === fields.length && (
                      <Grid item xs={12}>
                        <Stack alignItems={"flex-start"} mt={2}>
                          <Button
                            disabled={isView}
                            variant={"outlined"}
                            color="success"
                            onClick={() =>
                              append({
                                content: "",
                                isIncludesImage: false,
                                includesImage: "",
                              })
                            }
                            startIcon={<Add />}
                          >
                            Add More
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Box>
            )}
          </Paper>
        </form>
      </DialogContent>
      <DialogActions className="p-16">
        <Stack spacing={2} direction={"row"} justifyContent={"end"}>
          <Button
            sx={{ width: "150px" }}
            disabled={isView}
            variant={"contained"}
            type={"submit"}
            form={`form-golf-course`}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Drawer>
  );
};

export default GolfCourseForm;
