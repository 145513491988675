import { Box, Grid } from "@mui/material";
import React from "react";
import { PackageItinerary } from "../../interfaces/reservation";

type Props = {
  itineraries: { images: string }[];
};
const ListImage = ({ itineraries }: Props) => {
  return (
    <>
      {itineraries && itineraries.length > 0 && (
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item md={itineraries.length < 2 ? 12 : 8}>
            <Box
              component="img"
              sx={{
                borderRadius: 4,
                width: "100%",
                height: { md: 460 },
                maxHeight: { md: 460 },
              }}
              // src={itineraries[0]?.images.length > 0 ? JSON.parse(String(itineraries[0]?.images))[0] : ""}
              src={
                itineraries[0]?.images
                  ? (
                      String(itineraries[0]?.images) ?? ""
                    ).includes("https")
                    ? String(itineraries[0]?.images)
                    : `${process.env.REACT_APP_URL_S3_IMAGE}/${
                        process.env.REACT_APP_BUCKET_FOLDER
                      }${String(itineraries[0]?.images)}`
                  : ""
              }
            />
          </Grid>
          {itineraries && itineraries.length > 1 && <Grid
            item
            md={4}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              },
            }}
          >
            <Grid container spacing={2}>
              {itineraries.slice(1, 5).map((item, i) => !!item.images ? (
                <Grid item md={itineraries.length > 3 ? 6 : 12} key={i}>
                  <Box
                    component="img"
                    sx={{
                      borderRadius: 4,
                      width: "100%",
                      height: { lg: itineraries.length > 2 ? 220 : 460 },
                    }}
                    // src={JSON.parse(item.images)[0]}
                    src={
                      (item.images ?? "").includes("https")
                        ? item.images
                        : `${process.env.REACT_APP_URL_S3_IMAGE}/${
                            process.env.REACT_APP_BUCKET_FOLDER
                          }${item.images}`
                    }
                  />
                </Grid>
              ) : null)}
            </Grid>
          </Grid>}
        </Grid>
      )}
    </>
  );
};

export default ListImage;
