import { Grid, Typography } from "@mui/material";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { GolfCourseHandleForm } from "../../../../interfaces/golfCourse";
import SmartImageUpload from "../../../../components/ImageUpload/SmartImageUpload";
type Props = {
  control: Control<GolfCourseHandleForm>;
  isEdit: boolean;
};
const GroupCoverImage = ({ control, isEdit}: Props) => {
  const { fields, append, remove } = useFieldArray({
    name: "coverImage",
    control,
  });
  return (
    <>
    <Typography variant="h6" gutterBottom> Images (Maximum: 3)</Typography>
    <Grid container spacing={2}>
      {fields.map((item, index) => (
        <Grid item md={4}>
          <Controller
          key={fields.length}
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <SmartImageUpload
                  isEdit={isEdit}
                  type={"single"}
                  onChange={(value) =>
                    onChange(value)
                  }
                  id={`coverImage.${index}.images`}
                  defaultValue={value}
                  label={"Image (1120 × 690 px)"}
                  height={400}
                  width={500}
                  folder={"itinerary"}
                />
              );
            }}
            name={`coverImage.${index}.images`}
          />
        </Grid>
      ))}
     </Grid>
    </>
  );
};

export default GroupCoverImage;
